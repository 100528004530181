<script setup lang="ts">
import type { IconName } from '../ui/Icon.vue';

// Composables
const route = useRoute();

// Data
const isExpanded = ref(false);

// Stores
const appService = appStore();
const globalService = globalStore();

// Methods
const energyTypeIcon = (type: string): IconName => {
  if (type === 'Electricity') return 'Zap';
  if (type === 'Gas') return 'Flame';
  if (type === 'Water') return 'Droplets';
  return 'Zap';
};

const meterType = computed(() => {
  const meters = meterStore().getMeters;
  return meters.filter((meter) => meter.id === parseInt(route.params.id as string))[0]?.meter_type.energy_type;
});

// Computed
const ressourcesType = computed(() => {
  const meters = areaStore().getAreas.filter((area) => area.parent_id === null)[0]?.meters ?? [];
  if (meters.length === 0) return [];
  return meters.map((meter) => {
    return {
      id: meter.id,
      type: meter.meter_type.energy_type,
    };
  });
});
</script>

<template>
  <router-link
    v-if="ressourcesType.length > 0"
    class="group flex items-center justify-between px-3 py-2 rounded-lg truncate"
    :class="[route.path.includes('/explore') ? ' text-primary-500 bg-[#F4F4F5]' : 'hover:bg-[#F4F4F5] text-[#52525B] cursor-pointer']"
    :to="globalService.getSelectedSite.length > 1 ? '/explore/global' : '/explore/consumption/' + ressourcesType[0].id"
    @click="isExpanded = !isExpanded"
  >
    <div class="flex items-center">
      <ui-icon
        name="BarChartBig"
        :stroke-width="2"
        class="h-4 w-4 text-gray-500 transition mr-4"
        :class="[route.path.includes('/explore') ? 'stroke-primary-500' : 'text-[#52525B]']"
      />
      <span :title="$t('navigation.explore')" class="text-sm">
        {{ $t('navigation.explore') }}
      </span>
    </div>
    <ui-icon
      v-if="ressourcesType.length > 1"
      name="ChevronRight"
      class="h-5 w-5 text-gray-500 transition"
      :class="isExpanded ? 'transform rotate-90' : ''"
    />
  </router-link>
  <div v-if="isExpanded && ressourcesType.length > 1" class="pl-4">
    <div v-for="ressource in ressourcesType" :key="ressource.type">
      <router-link
        v-if="ressource.type === 'Water'"
        :to="`/explore-water`"
        class="group flex items-center justify-start px-3 py-1 rounded-lg truncate"
        :class="[
          route.path.includes('/explore-water') ? ' text-primary-500 bg-[#F4F4F5]' : 'hover:bg-[#F4F4F5] text-[#52525B] cursor-pointer',
        ]"
      >
        <ui-icon
          :name="energyTypeIcon(ressource.type)"
          class="h-3.5 mr-3 text-gray-500 transition"
          :class="[route.path.includes('/explore-water') ? 'stroke-primary-500' : 'text-[#52525B]']"
        />
        <span class="text-sm">
          {{ $t(`global.energy_type.${ressource.type}`) }}
        </span>
      </router-link>
      <router-link
        v-else
        class="group flex items-center justify-start px-3 py-1 rounded-lg truncate"
        :to="`/explore/consumption/${ressource.id}`"
        :class="[meterType === ressource.type ? ' text-primary-500 bg-[#F4F4F5]' : 'hover:bg-[#F4F4F5] text-[#52525B] cursor-pointer']"
        @click="appService.setMeter(ressource.id)"
      >
        <ui-icon
          :name="energyTypeIcon(ressource.type)"
          class="h-3.5 mr-3 text-gray-500 transition"
          :class="[meterType === ressource.type ? 'stroke-primary-500' : 'text-[#52525B]']"
        />
        <span class="text-sm">{{ $t(`global.energy_type.${ressource.type}`) }}</span>
      </router-link>
    </div>
  </div>
</template>
