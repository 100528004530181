<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { Dropdown } from '../ui/Dropdown.vue';

// Composables
const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const crisp = useScriptCrisp({
  id: '6eb8f29f-9750-4c5d-980e-a54b63c1c888',
});
crisp.do('chat:close');
crisp.do('chat:hide');

// Data
const isExpanded = ref(false);
const notificationModalStatus = ref(false);

const selectSite = async (id: number) => {
  globalStore().setSelectedSite(id);
  globalStore().initApp();
  router.push('/');
};

const selectClient = async (id: number) => {
  globalStore().setSelectedClient(id);
  globalStore().initApp();
  router.push('/');
};

// Computed
const getSites = computed<Dropdown[]>(() => {
  const items: Dropdown[] = siteStore().getSites.map((sites) => {
    return {
      key: sites.id,
      label: sites.name,
    };
  });

  items.unshift({
    key: 'all',
    label: t('global.all_sites'),
  });

  return items;
});

const getClients = computed(() => {
  const clients = clientStore().getClients;
  return clients.map((client) => {
    return {
      key: client.id,
      label: client.name,
    };
  });
});

// Computed
const path = computed(() => route.path);

const loading = computed(() => globalStore().getLoading);

const user = computed(() => {
  return userStore().getCurrentUser;
});

const currentClient = computed(() => {
  return globalStore().getSelectedClient;
});

const currentSite = computed(() => {
  if (globalStore().getSelectedSite.length > 1) {
    return 'all';
  }
  return globalStore().getSelectedSite[0];
});

const getNumberOfNotifications = computed(() => {
  return notificationStore().getNotifications.length;
});

const isCrispChatOpen = ref(false);

function toggleCrispChat() {
  if (isCrispChatOpen.value) {
    crisp.do('chat:hide');
    crisp.do('chat:close');
    isCrispChatOpen.value = false;
  } else {
    crisp.do('chat:show');
    crisp.do('chat:open');
    isCrispChatOpen.value = true;
  }
}
crisp.on('chat:closed', () => {
  window.$crisp.push(['do', 'chat:hide']);
  isCrispChatOpen.value = false;
});
</script>

<template>
  <header v-if="!loading && user" class="flex w-[220px] h-screen">
    <nav class="flex w-[220px] flex-col h-full flex-1 justify-between gap-2 p-2 text-[#52525B] border-r border-[#E7E8E9] overflow-y-auto">
      <div class="flex flex-col gap-2 md:gap-4 my-2 pb-2 md:pb-4 border-b border-[#F4F4F5]">
        <nuxt-link class="flex items-center gap-1 w-fit px-1 text-3xl font-bold text-primary-500 font-mono" to="/">
          <img src="/assets/nrjx-logo.svg" alt="Nrjx logo" class="w-8 h-8" />
          <p>nrjx</p>
        </nuxt-link>

        <div v-if="getSites.length > 2 || user?.role === 'admin_app'" class="border-b border-[#F4F4F5]" />
        <ui-dropdown
          v-if="user.role === 'admin_app'"
          :model-value="currentClient"
          fit-to-select
          searchable
          :items="getClients"
          menu-items-class="left-0"
          @select-item="selectClient($event)"
        />
        <ui-dropdown
          v-if="getSites.length > 2"
          v-model="currentSite"
          fit-to-select
          :items="getSites"
          menu-items-class="left-0"
          @select-item="selectSite($event)"
        />
      </div>

      <div class="flex flex-col gap-2">
        <nuxt-link
          class="group flex items-center gap-4 px-3 py-2 rounded-lg truncate"
          :class="['/' === path ? ' text-primary-500 bg-[#F4F4F5]' : 'hover:bg-[#F4F4F5] text-[#52525B] cursor-pointer']"
          to="/"
        >
          <ui-icon
            class="h-4 w-4 text-gray-500 transition"
            :class="[path === '/' ? 'stroke-primary-500' : 'text-[#52525B]']"
            :stroke-width="2"
            name="Presentation"
          />
          <span :title="$t('global.dashboard')" class="text-sm">
            {{ $t('global.dashboard') }}
          </span>
        </nuxt-link>

        <div
          v-if="currentSite !== 'all'"
          class="group flex items-center justify-between px-3 py-2 rounded-lg truncate"
          :class="[
            route.path.includes('/tracking') ? ' text-primary-500 bg-[#F4F4F5]' : 'hover:bg-[#F4F4F5] text-[#52525B] cursor-pointer',
          ]"
          @click="isExpanded = !isExpanded"
        >
          <div class="flex items-center">
            <ui-icon
              name="MoveUpRight"
              :stroke-width="2"
              class="h-4 w-4 text-gray-500 transition mr-4"
              :class="[route.path.includes('/tracking') ? 'stroke-primary-500' : 'text-[#52525B]']"
            />

            <span :title="$t('nav.tracking')" class="text-sm">{{ $t('nav.tracking') }}</span>
          </div>
          <ui-icon name="ChevronRight" class="h-5 w-5 text-gray-500 transition" :class="isExpanded ? 'transform rotate-90' : ''" />
        </div>
        <div v-if="isExpanded" class="pl-4">
          <nuxt-link
            class="group flex items-center justify-start px-3 py-1 rounded-lg truncate"
            to="/tracking/products"
            :class="[
              route.path.includes('/tracking/product/') || route.path === '/tracking/products'
                ? ' text-primary-500 bg-[#F4F4F5]'
                : 'hover:bg-[#F4F4F5] text-[#52525B] cursor-pointer',
            ]"
          >
            <span class="text-sm">{{ $t('nav.products') }}</span>
          </nuxt-link>
          <nuxt-link
            class="group flex items-center justify-start px-3 py-1 rounded-lg truncate"
            to="/tracking/productions"
            :class="[
              route.path.includes('/tracking/production')
                ? ' text-primary-500 bg-[#F4F4F5]'
                : 'hover:bg-[#F4F4F5] text-[#52525B] cursor-pointer',
            ]"
          >
            <span class="text-sm">{{ $t('nav.production') }}</span>
          </nuxt-link>
        </div>

        <nav-analytics />

        <nuxt-link
          class="group flex items-center gap-4 px-3 py-2 rounded-lg truncate"
          :class="[path.includes('/incident') ? ' text-primary-500 bg-[#F4F4F5]' : 'hover:bg-[#F4F4F5] text-[#52525B] cursor-pointer']"
          to="/incidents"
        >
          <ui-icon
            :class="[path.includes('/incident') ? 'stroke-primary-500' : 'text-[#52525B]']"
            class="h-4 w-4"
            :stroke-width="2"
            name="TriangleAlert"
          />
          <span :title="$t('global.incidents')" class="text-sm">{{ $t('global.incidents') }}</span>
        </nuxt-link>

        <nuxt-link
          class="group flex items-center gap-4 px-3 py-2 rounded-lg truncate"
          :class="[path.includes('/action') ? ' text-primary-500 bg-[#F4F4F5]' : 'hover:bg-[#F4F4F5] text-[#52525B] cursor-pointer']"
          to="/actions"
        >
          <ui-icon
            name="CircleCheckBig"
            :stroke-width="2"
            class="h-4 w-4"
            :class="[path.includes('/action') ? 'stroke-primary' : 'text-[#52525B]']"
          />
          <span :title="$t('navigation.action_plan')" class="text-sm">{{ $t('navigation.action_plan') }}</span>
        </nuxt-link>
      </div>

      <div class="flex flex-col gap-3 mt-auto">
        <div class="flex flex-col gap-1.5 pb-4 border-b border-[#F4F4F5]">
          <button
            class="group flex items-center gap-4 px-3 py-2 my-auto h-fit rounded-lg truncate hover:bg-[#F4F4F5] text-[#52525B]"
            @click="notificationModalStatus = true"
          >
            <div class="relative">
              <ui-icon
                :class="[path.includes('/notifications') ? 'stroke-primary' : 'text-[#52525B]']"
                class="h-4 w-4"
                :stroke-width="2"
                name="Bell"
              />

              <div
                v-if="getNumberOfNotifications > 0"
                class="h-4 w-4 bg-primary text-[10px] text-white rounded-full absolute -top-2 -right-1.5 flex items-center justify-center after:absolute after:content-[''] after:w-3 after:h-3 after:bg-primary after:rounded-full after:animate-ping after:-z-10"
                :class="{ 'after:hidden': getNumberOfNotifications === 0 }"
              >
                {{ getNumberOfNotifications }}
              </div>
            </div>
            <span class="text-sm">{{ t('global.notifications') }}</span>
          </button>

          <nuxt-link
            class="group flex items-center gap-4 px-3 py-2 rounded-lg truncate"
            :class="[
              path.includes('/configuration') ? ' text-primary-500 bg-[#F4F4F5]' : 'hover:bg-[#F4F4F5] text-[#52525B] cursor-pointer',
            ]"
            to="/configuration/alerts"
          >
            <ui-icon
              :class="[path.includes('/configuration') ? 'stroke-primary' : 'text-[#52525B]']"
              class="h-4 w-4"
              :stroke-width="2"
              name="Settings"
            />
            <span :title="$t('global.configurations')" class="text-sm">{{ $t('global.configurations') }}</span>
          </nuxt-link>

          <button
            class="group flex items-center gap-4 px-3 py-2 text-sm rounded-lg truncate hover:bg-[#F4F4F5] cursor-pointer"
            :class="isCrispChatOpen ? 'bg-[#F4F4F5] text-primary-500' : 'text-[#52525B]'"
            @click="toggleCrispChat()"
          >
            <ui-icon name="CircleHelp" class="h-4.5 w-4.5' : 'h-4 w-4" />
            {{ $t('global.help') }}
          </button>

          <nuxt-link
            v-if="user.role === 'admin_app'"
            class="group flex items-center gap-4 px-3 py-2 rounded-lg truncate"
            :class="[path.includes('/admin') ? ' text-primary-500 bg-[#F4F4F5]' : 'hover:bg-[#F4F4F5] text-[#52525B] cursor-pointer']"
            to="/admin"
          >
            <ui-icon
              :class="[path.includes('/admin') ? 'stroke-primary' : 'text-[#52525B]']"
              class="h-4 w-4"
              :stroke-width="2"
              name="Lock"
            />
            <span class="text-sm">{{ $t('global.admin') }}</span>
          </nuxt-link>

          <nuxt-link
            v-if="user.role === 'admin_app'"
            class="group flex items-center gap-4 px-3 py-2 rounded-lg truncate"
            :class="[
              path.includes('/component-library') ? ' text-primary-500 bg-[#F4F4F5]' : 'hover:bg-[#F4F4F5] text-[#52525B] cursor-pointer',
            ]"
            to="/component-library/button"
          >
            <ui-icon
              :class="[path.includes('/component-library') ? 'stroke-primary' : 'text-[#52525B]']"
              class="h-4 w-4"
              :stroke-width="2"
              name="Puzzle"
            />
            <span class="text-sm">{{ $t('global.components') }}</span>
          </nuxt-link>
        </div>

        <div class="flex justify-between gap-2 w-full">
          <ui-nav-account-menu />
        </div>
      </div>
    </nav>
  </header>
  <notification-modal v-model="notificationModalStatus" />
</template>

<style scoped>
div.crisp-client .cc-1brb6 .cc-1yy0g .cc-1no03 {
  bottom: 20px !important;
}

div.crisp-client .cc-1brb6 .cc-1yy0g .cc-1m2mf {
  display: none !important;
}
</style>
