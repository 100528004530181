<script setup lang="ts">
import { onClickOutside, useElementBounding } from '@vueuse/core';
import dayjs from 'dayjs';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

import { userStore } from '~/stores/user';

// Composables
const { t, locale } = useI18n();
const router = useRouter();
const route = useRoute();

// Data
const state = reactive({
  isMobile: false,
});
const menu = ref(false);
const menuLang = ref(false);
const localesLangs = computed(() => [
  {
    label: t('languages.french'),
    key: 'fr',
  },
  {
    label: t('languages.english'),
    key: 'en',
  },
]);

// Button
const button = ref<HTMLButtonElement | null>(null);
const { width, update } = useElementBounding(button);

// Menu
const menuElement = ref<HTMLButtonElement | null>(null);
onClickOutside(menuElement, () => ((menu.value = false), (menuLang.value = false)));
const { left: leftMenu, width: widthMenu, update: updateMenu } = useElementBounding(menuElement);

// Methods
function loadData() {
  state.isMobile = window.innerWidth < 768;
}

function changeLanguage(lang: string) {
  locale.value = lang;
  menuLang.value = false;
  dayjs.locale(locale.value);
  useHead({
    htmlAttrs: {
      lang: locale.value,
    },
  });
}

const openMenu = () => {
  update();
  menu.value = !menu.value;
  updateMenu();
};

async function logoutUser() {
  await firebaseStore().logout();
  router.push('/auth/login');
}

// Computed
const user = computed(() => {
  return userStore().getCurrentUser;
});

// Mounted
onMounted(() => {
  loadData();
  useHead({
    htmlAttrs: {
      lang: locale.value,
    },
  });
});
</script>

<template>
  <div
    ref="button"
    class="group flex items-center gap-4 px-3 py-2 rounded-lg relative w-full hover:bg-gray-50 cursor-pointer select-none"
    @click="openMenu()"
  >
    <ui-avatar class="w-6 h-6" :photo-url="user.picture_url" :firstname="user.first_name" :lastname="user.last_name" />
    <p class="text-[#52525B] text-sm">
      {{ `${user.first_name} ${user.last_name}` }}
    </p>
  </div>
  <transition
    enter-active-class="transition duration-100 ease-out"
    enter-from-class="transform scale-y-95 opacity-0"
    enter-to-class="transform scale-y-100 opacity-100"
    leave-active-class="transition duration-75 ease-in"
    leave-from-class="transform scale-y-100 opacity-100"
    leave-to-class="transform scale-y-95 opacity-0"
  >
    <div
      v-if="menu"
      ref="menuElement"
      class="absolute z-[8000] mt-2 origin-bottom bottom-2 p-1 border-[#F4F4F5] border divide-gray-100 rounded-md bg-white shadow-lg focus:outline-none"
      :style="`left: ${width + 24}px`"
    >
      <!-- Languages -->
      <div class="select-none cursor-pointer flex items-center px-4 py-2 text-sm rounded-md hover:bg-gray-100" @click="menuLang = true">
        <ui-icon name="Globe" :size="16" />
        <p class="whitespace-nowrap text-md ml-2">
          {{ t('global.languages') }}
        </p>
      </div>

      <router-Link
        to="/profile"
        :class="route.path === '/profile' ? 'bg-gray-50' : 'hover:bg-gray-50'"
        class="select-none cursor-pointer flex items-center px-4 py-2 text-sm rounded-md"
        @click="((menu = false), (menuLang = false))"
      >
        <ui-icon name="User" :size="16" />
        <p class="whitespace-nowrap text-md ml-2">
          {{ t('profile.my_profile') }}
        </p>
      </router-Link>

      <div
        class="select-none cursor-pointer flex items-center px-4 py-2 text-sm rounded-md hover:bg-gray-100 text-red-500"
        @click="logoutUser()"
      >
        <ui-icon name="LogOut" :size="16" />
        <p class="whitespace-nowrap text-md ml-2">
          {{ t('global.logout') }}
        </p>
      </div>
    </div>
  </transition>
  <transition
    enter-active-class="transition duration-100 ease-out"
    enter-from-class="transform scale-y-95 opacity-0"
    enter-to-class="transform scale-y-100 opacity-100"
    leave-active-class="transition duration-75 ease-in"
    leave-from-class="transform scale-y-100 opacity-100"
    leave-to-class="transform scale-y-95 opacity-0"
  >
    <div
      v-if="menuLang"
      class="bg-white origin-bottom p-1 z-[8000] absolute divide-gray-100 border-[#F4F4F5] border rounded-md shadow-lg group-hover:opacity-100 transition-opacity duration-500"
      :style="`left: ${leftMenu + widthMenu + 4}px; bottom: ${12 + 36 + 36}px;`"
    >
      <div
        v-for="language in localesLangs"
        :key="language.label"
        class="group flex w-full items-center gap-2 rounded-md px-4 h-[32px] text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
        @click="changeLanguage(language.key)"
      >
        <span>{{ language.label }}</span>
      </div>
    </div>
  </transition>
</template>
